import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DataHealthIcon from 'app/components/shared/icons/data-health';
import useUserData from 'hooks/useUserData';
import { setIsFeedbackDrawerOpen, setIsUserDropdownOpen } from 'store/reducers/navbar-reducer';
import { ThemeContext } from 'styled-components';

import SideDrawer from '../CommonStyled/SideDrawer';
import FeedbackDrawer from '../ContactDrawers/FeedbackDrawer';
import { InfoIcon, MessageIcon, PlayCircleIcon, QuestionIcon, Sparkle } from '../shared/icons';
import PersonIcon from '../shared/icons/person-icon';
import { NavButton } from './styled';

export default function MenuButtons() {
  const { push } = useHistory();
  const user = useUserData();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const buttonsData = [
    {
      Icon: <PersonIcon />,
      title: 'Profile',
      show: true,
      onClick: () => {
        push({ pathname: `/my-profile`, state: { scrollToTop: true } });
      },
    },
    {
      Icon: <InfoIcon />,
      title: 'Privacy policy',
      show: true,
      onClick: () => window.open('https://kyu.com/legal/employee-privacy-notice', '_blank'),
    },
    {
      Icon: <DataHealthIcon />,
      title: 'Data Health',
      show: user.allow_collective_data_health_resource ? true : false,
      onClick: () => {
        push({
          pathname: `/resources/${user.allow_collective_data_health_resource}/embed`,
          state: { scrollToTop: true },
        });
      },
    },
    {
      Icon: <Sparkle />,
      title: 'Creative Intelligence Report',
      show: user.creative_intelligence_report,
      onClick: () => {
        push({
          pathname: `/resources/creative-intelligence-report/embed`,
          state: { scrollToTop: true },
        });
      },
    },
    {
      Icon: <MessageIcon />,
      title: 'Feedback',
      show: true,
      onClick: () => {
        dispatch(setIsFeedbackDrawerOpen(true));
      },
    },
    {
      Icon: <QuestionIcon />,
      title: 'FAQ',
      show: true,
      onClick: () => {
        push({ pathname: `/kyu-101`, hash: 'faq' });
      },
    },
    {
      Icon: <PlayCircleIcon />,
      title: 'kyu 101',
      show: true,
      onClick: () => {
        push({ pathname: `/resources/kyu-storybook` });
      },
    },
  ];

  return (
    <>
      {buttonsData
        .filter(({ show }) => show)
        .map(({ title, Icon, onClick }) => (
          <NavButton
            key={title}
            onClick={() => {
              onClick();
              dispatch(setIsUserDropdownOpen(false));
            }}
          >
            {React.cloneElement(Icon, { width: 16, height: 16, fill: theme.navBarIconColor })}
            <span>{title}</span>
          </NavButton>
        ))}
    </>
  );
}
