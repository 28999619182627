import React from 'react';

export default function MinimalTrendingIcon({ width = 14, height = 14, fill = '#171C33' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_36237_18984)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.995812 10.9125C0.768006 11.1403 0.39866 11.1403 0.170854 10.9125C-0.0569515 10.6847 -0.0569515 10.3153 0.170854 10.0875L4.54585 5.7125C4.77366 5.48469 5.14301 5.48469 5.37081 5.7125L7.875 8.21669L12.0084 4.08331H9.91667C9.5945 4.08331 9.33333 3.82215 9.33333 3.49998C9.33333 3.17781 9.5945 2.91665 9.91667 2.91665H13.4152C13.4199 2.91664 13.4246 2.91668 13.4293 2.91678C13.5031 2.91835 13.5736 2.93363 13.6382 2.96018C13.7077 2.98865 13.7727 3.03109 13.8291 3.0875C13.943 3.2014 14 3.35069 14 3.49998V6.99998C14 7.32215 13.7388 7.58331 13.4167 7.58331C13.0945 7.58331 12.8333 7.32215 12.8333 6.99998V4.90827L8.28748 9.45412C8.05967 9.68193 7.69033 9.68193 7.46252 9.45412L4.95833 6.94994L0.995812 10.9125Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_36237_18984">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
