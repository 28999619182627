import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ClientIcon,
  CompanyIcon,
  DisciplineIcon,
  IndustryIcon,
  PassionIcon,
  PeopleIcon,
  ProjectIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import GlobalClientDrawer from 'app/components/Drawers/GlobalClient/GlobalClientDrawer';
import GlobalCompanyDrawer from 'app/components/Drawers/GlobalCompany/GlobalCompanyDrawer';
import GlobalDisciplineDrawer from 'app/components/Drawers/GlobalDiscipline/GlobalDisciplineDrawer';
import GlobalIndustryDrawer from 'app/components/Drawers/GlobalIndustry/GlobalIndustryDrawer';
import GlobalPassionDrawer from 'app/components/Drawers/GlobalPassion/GlobalPassionDrawer';
import GlobalProjectDrawer from 'app/components/Drawers/GlobalProject/GlobalProjectDrawer';
import GlobalSkillDrawer from 'app/components/Drawers/GlobalSkill/GlobalSkillDrawer';
import { useResourceClick } from 'app/components/Resources/hooks/useResourceClick';
import {
  BookIcon,
  CubeIcon,
  MinimalTrendingIcon,
  ResourceIcon,
  WorldGlobe,
} from 'app/components/shared/icons';
import Calendar from 'app/components/shared/icons/calendar';
import CommunitiesIcon from 'app/components/shared/icons/communities-icon';
import LightningIcon from 'app/components/shared/icons/lightning-icon';
import PersonIcon from 'app/components/shared/icons/person-icon';
import TrendingIcon from 'app/components/shared/icons/trending';
import { User } from 'interfaces';
import { userDataSelector } from 'store/reducers/user-reducer';
import { ThemeProvider } from 'styled-components';

import PersonAvatar from '../PersonAvatar';
import SideDrawer from '../SideDrawer';
import { PillContainer, PillText, PillWrapper } from './styled';
import { theme } from './theme';

export type DataTypePillType =
  | 'industry'
  | 'client'
  | 'company'
  | 'city'
  | 'project'
  | 'passion'
  | 'kyu_skill'
  | 'community'
  | 'locations'
  | 'country'
  | 'language'
  | 'category'
  | 'category_secondary'
  | 'person'
  | 'convening'
  | 'article'
  | 'tool'
  | 'resource'
  | 'discipline'
  | 'levels'
  | 'tenure'
  | 'work_experience'
  | 'more';

interface DataTypePillProps {
  type: DataTypePillType;
  title: string;
  slug?: string;
  clickable?: boolean;
  shared?: boolean;
  onClick?: () => void;
  themeMode?: 'light' | 'dark' | 'high-contrast';
  behavioralFunction?: () => void;
  style?: React.CSSProperties;
  resource_type_name?: string;
  go_to_detail_page?: boolean;
  link?: string;
}

const ICON_WIDTH = 13;
const ICON_HEIGHT = 13;

const ICONS = {
  company: <CompanyIcon fill={'#5478A1'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  client: <ClientIcon fill={'#104D5B'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  project: <ProjectIcon fill={'#392B62'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  industry: <IndustryIcon fill={'#8DA76C'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  kyu_skill: <SkillIcon fill={'#EB823D'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  passion: <PassionIcon fill={'#D47563'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  community: <CommunitiesIcon fill={'#A26565'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  person: <PersonIcon fill={'rgba(255, 194, 41, 1)'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  locations: null,
  language: <PeopleIcon fill={'rgba(255, 194, 41, 1)'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  city: <WorldGlobe fill={'rgba(255, 194, 41, 1)'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  country: <WorldGlobe fill={'rgba(255, 194, 41, 1)'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  category: null,
  convening: <Calendar fill={'#A26565'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  article: <BookIcon fill={'var(--color-primary)'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  tool: <LightningIcon fill={'var(--color-primary)'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  resource: <ResourceIcon fill={'var(--color-primary)'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  discipline: <DisciplineIcon fill={'#5478A1'} width={ICON_WIDTH} height={ICON_HEIGHT} />,
  levels: null,
  tenure: <CubeIcon fill="rgba(23, 28, 51, 0.5)" width={ICON_WIDTH} height={ICON_HEIGHT} />,
  work_experience: <MinimalTrendingIcon width={ICON_WIDTH} height={ICON_HEIGHT} />,
};

const DataTypePill = React.forwardRef<HTMLDivElement, DataTypePillProps>(
  (
    {
      type,
      title,
      slug,
      clickable = true,
      shared = false,
      onClick = () => {},
      themeMode = 'light',
      behavioralFunction,
      style,
      go_to_detail_page,
      resource_type_name,
      link,
    },
    ref
  ) => {
    const { push } = useHistory();
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const userSelector: User = useSelector(userDataSelector);
    const handleResourceClick = useResourceClick();

    const handleNonDrawerTypesClick = () => {
      if (type === 'community') {
        return push(`/community/${slug}`);
      }

      if (type === 'convening') {
        return push(`/convening/${slug}`);
      }

      if (type === 'article') {
        return push(`/articles/${slug}`);
      }

      if (type === 'tool') {
        return push(`/tools/${slug}`);
      }

      if (type === 'resource') {
        handleResourceClick({
          go_to_detail_page,
          link,
          resource_type_name,
          slug,
        });
      }
    };

    const handleClick = () => {
      if (clickable) {
        if (behavioralFunction) {
          behavioralFunction();
        }
        if (slug) {
          if (['community', 'convening', 'article', 'tool', 'resource'].includes(type)) {
            handleNonDrawerTypesClick();
          } else {
            setShowDrawer(true);
          }
        } else if (onClick) {
          onClick();
        }
      }
    };

    return (
      <ThemeProvider theme={theme[themeMode]}>
        <PillWrapper ref={ref} style={style}>
          <PillContainer
            type={type}
            clickable={clickable}
            onClick={handleClick}
            svgMinWidth={ICON_WIDTH}
          >
            {ICONS[type]
              ? React.cloneElement(ICONS[type], { fill: theme[themeMode].icon[type] })
              : null}
            <PillText type={type}>{title}</PillText>
          </PillContainer>
          {shared ? (
            <PersonAvatar
              name={userSelector.first_name}
              lastName={userSelector.last_name}
              avatar={userSelector.profile_image}
              size={22}
              highlighted
            />
          ) : null}
          {showDrawer ? (
            <SideDrawer closeDrawer={() => setShowDrawer(false)} withClose>
              <>
                {type === 'company' && <GlobalCompanyDrawer slug={slug} />}
                {type === 'industry' && <GlobalIndustryDrawer slug={slug} />}
                {type === 'client' && <GlobalClientDrawer slug={slug} />}
                {type === 'passion' && <GlobalPassionDrawer slug={slug} />}
                {type === 'kyu_skill' && <GlobalSkillDrawer slug={slug} />}
                {type === 'project' && <GlobalProjectDrawer slug={slug} />}
                {type === 'discipline' && <GlobalDisciplineDrawer slug={slug} />}
              </>
            </SideDrawer>
          ) : null}
        </PillWrapper>
      </ThemeProvider>
    );
  }
);

export default DataTypePill;
