import * as React from 'react';

const CloseIconSmall = ({ width = 11, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip-close-small)">
        <path
          d="M0.698242 10.2202L10.3014 0.779297"
          stroke="#1F2025"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M10.4762 10.4808L0.436523 0.433594"
          stroke="#1F2025"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip-close-small">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIconSmall;
