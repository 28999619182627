import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnimatePresence } from 'framer-motion';
import { setIsDrawerOpen } from 'store/reducers/navbar-reducer';
import { showResourceSubmissionModalSelector } from 'store/reducers/resource-submission/resource-submission-reducer';

const ResourceSubmissionModal = lazy(() => import('../ResourceSubmissionModal'));

export default function ResourceSubmissionHandler() {
  const dispatch = useDispatch();
  const showResourceSubmissionModal = useSelector(showResourceSubmissionModalSelector);

  useEffect(() => {
    dispatch(setIsDrawerOpen(showResourceSubmissionModal));
  }, [showResourceSubmissionModal]);

  return (
    <AnimatePresence>
      {showResourceSubmissionModal ? (
        <Suspense fallback={<></>}>
          <ResourceSubmissionModal />
        </Suspense>
      ) : null}
    </AnimatePresence>
  );
}
