import React from 'react';

export default function CubeIcon({ width = 13, height = 14, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.375 9.1667V4.83336C11.3748 4.64339 11.3247 4.4568 11.2296 4.29232C11.1345 4.12785 10.9979 3.99127 10.8333 3.89628L7.04167 1.72961C6.87698 1.63453 6.69016 1.58447 6.5 1.58447C6.30984 1.58447 6.12302 1.63453 5.95833 1.72961L2.16667 3.89628C2.00214 3.99127 1.86549 4.12785 1.77042 4.29232C1.67534 4.4568 1.62519 4.64339 1.625 4.83336V9.1667C1.62519 9.35667 1.67534 9.54326 1.77042 9.70773C1.86549 9.87221 2.00214 10.0088 2.16667 10.1038L5.95833 12.2704C6.12302 12.3655 6.30984 12.4156 6.5 12.4156C6.69016 12.4156 6.87698 12.3655 7.04167 12.2704L10.8333 10.1038C10.9979 10.0088 11.1345 9.87221 11.2296 9.70773C11.3247 9.54326 11.3748 9.35667 11.375 9.1667Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.77124 4.27002L6.49999 7.00544L11.2287 4.27002"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.5 12.46V7" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
