import { IBehavioral } from 'interfaces';

import { http } from './http';

interface BehavioralResponse {
  behavioral_id: string;
}

export const getBehavioralId = ({
  object_type = null,
  uuid = null,
  entry_point_type = null,
  query_type = 'direct',
}) => {
  return http.post<IBehavioral>('/behaviorals', {
    object_type,
    uuid,
    entry_point_type,
    query_type,
  });
};

export const putBehavioralEmailCopied = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/email_copied`, { behavioral_id, action_details });
};

export const putBehavioralMapOpen = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/map_open`, { behavioral_id });
};

export const putBehavioralPlatformLinkCopied = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/platform_link_copied`, { behavioral_id });
};

export const putBehavioralShareBannerClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/share_banner_clicked`, { behavioral_id });
};

export const putBehavioralFeatureBannerDismissed = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/feature_banner_dismissed`, { behavioral_id });
};

export const putBehavioralFeatureBannerCTAClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/feature_banner_cta_clicked`, { behavioral_id });
};

export const putBehavioralGlobalMapOpen = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/global_map_open`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralResultsCard = ({ behavioral_id, card_type }) => {
  return http.put(`/behaviorals/${behavioral_id}/result_type_clicked`, {
    behavioral_id,
    card_type,
  });
};

export const putBehavioralDetailsAnnouncementClicked = ({
  behavioral_id,
  object_uuid,
  object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/details_announcement_clicked`, {
    behavioral_id,
    object_uuid,
    object_type,
  });
};

export const putBehavioralGetInvolvedClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/get_involved_clicked`, { behavioral_id });
};

export const putBehavioralGetInvolvedLinkClicked = ({ behavioral_id, link_clicked }) => {
  return http.put(`/behaviorals/${behavioral_id}/get_involved_link_clicked`, {
    behavioral_id,
    link_clicked,
  });
};

export const putBehavioralBlogPostClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/blog_post_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralAvatarClicked = ({ behavioral_id, person_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/avatar_clicked`, { behavioral_id, person_id });
};

export const putBehavioralRegistrationLinkClicked = ({ behavioral_id, convening_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/registration_link_clicked`, {
    behavioral_id,
    convening_id,
  });
};

export const putBehavioralWatchRecapClicked = ({ behavioral_id, convening_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/watch_recap_clicked`, {
    behavioral_id,
    convening_id,
  });
};

export const putBehavioralViewedBlogPostPage = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/viewed_blog_post_page`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralOpenToolEmbeddedPage = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/open_tool_embedded_page`, { behavioral_id });
};

export const putBehavioralDownloadTool = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/download_tool`, { behavioral_id });
};

export const putBehavioralLinkedinOpened = ({ behavioral_id, person_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/linkedin_opened`, { behavioral_id, person_id });
};

export const putBehavioralSlackOpened = ({ behavioral_id, person_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/slack_opened`, { behavioral_id, person_id });
};

export const putBehavioralFullProfileOpened = ({ behavioral_id, person_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/full_profile_opened`, {
    behavioral_id,
    person_id,
  });
};

export const putBehavioralConveningClicked = ({ behavioral_id, convening_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/convening_clicked`, {
    behavioral_id,
    convening_id,
  });
};

export const putBehavioralContinueExploringClicked = ({
  behavioral_id,
  object_uuid,
  object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/continue_exploring_clicked`, {
    behavioral_id,
    object_uuid,
    object_type,
  });
};

export const putBehavioralAnchorTagClicked = ({ behavioral_id, object_uuid, object_type }) => {
  return http.put(`/behaviorals/${behavioral_id}/anchor_tag_clicked`, {
    behavioral_id,
    object_uuid,
    object_type,
  });
};

export const putBehavioralItemClicked = ({ behavioral_id, object_uuid, object_type }) => {
  return http.put(`/behaviorals/${behavioral_id}/item_clicked`, {
    behavioral_id,
    object_uuid,
    object_type,
  });
};

export const putBehavioralNavAboutKyuOsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_about_kyu_os`, { behavioral_id });
};

export const putBehavioralNavNetworkMapsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_network_maps`, { behavioral_id });
};

export const putBehavioralNavTheWellClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_the_well`, { behavioral_id });
};

export const putBehavioralNavResourcesClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_resources`, { behavioral_id });
};

export const putBehavioralNavPeopleClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_people`, { behavioral_id });
};

export const putBehavioralNavCommunitiesClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_communities`, { behavioral_id });
};

export const putBehavioralNavEventsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_events`, { behavioral_id });
};

export const putBehavioralNavDiscoverClicked = ({ behavioral_id, object_uuid, object_type }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_discover`, {
    behavioral_id,
    object_uuid,
    object_type,
  });
};

export const putBehavioralNavHostAnEventClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_host_an_event`, { behavioral_id });
};

export const putBehavioralNavPitchAStoryClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_pitch_a_story`, { behavioral_id });
};

export const putBehavioralNavSubmitAResourceClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_submit_a_resource`, { behavioral_id });
};

export const putBehavioralNavOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_opened`, { behavioral_id });
};

export const putBehavioralNavHomepageOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_homepage_opened`, { behavioral_id });
};

export const putBehavioralNavSpotlightOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_spotlight_opened`, { behavioral_id });
};

export const putBehavioralNavSearchOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_search_opened`, { behavioral_id });
};

export const putBehavioralNavProfileMenuOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_profile_menu_opened`, { behavioral_id });
};

export const putBehavioralActivityFeedSpotlightClicked = ({
  behavioral_id,
  object_uuid,
  object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_spotlight`, {
    behavioral_id,
    object_uuid,
    object_type,
  });
};

export const putBehavioralActivityFeedRadarTrendingClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_radar_trending`, { behavioral_id });
};

export const putBehavioralActivityFeedRadarTrendingPillClicked = ({
  behavioral_id,
  object_uuid,
  object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_radar_trending_pill`, {
    behavioral_id,
    object_uuid,
    object_type,
  });
};

export const putBehavioralActivityFeedRadarActiveWorkClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_radar_active_work`, { behavioral_id });
};

export const putBehavioralActivityFeedRadarPressClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_radar_press`, { behavioral_id });
};

export const putBehavioralActivityFeedRadarEventsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_radar_events`, { behavioral_id });
};

export const putBehavioralActivityFeedMeetSomeoneNewClcked = ({ behavioral_id, object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_meet_someone_new`, {
    behavioral_id,
    object_uuid,
    object_type: 'people',
  });
};

export const putBehavioralAcitivityFeedMapGalleryClicked = ({
  behavioral_id,
  object_uuid,
  action_details,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/homepage_map_gallery`, {
    behavioral_id,
    object_type: 'map_gallery_item',
    object_uuid,
    action_details,
  });
};

// RESOURCES
export const putBehavioralResourceDetailsClicked = ({ behavioral_id, resource_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_details_clicked`, {
    behavioral_id,
    resource_id,
  });
};

export const putBehavioralTagCategoryClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_tag_category_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
  });
};

export const putBehavioralTagSearchClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_tag_search_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralResourceRelatedResourceClicked = ({
  behavioral_id,
  resource_id,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_related_resource_clicked`, {
    behavioral_id,
    resource_id,
    object_uuid: related_object_uuid,
    object_type: 'Resource',
  });
};

export const putBehavioralResourceRelatedPageClicked = ({
  behavioral_id,
  resource_id,
  related_object_uuid,
  related_object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_related_page_clicked`, {
    behavioral_id,
    resource_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

export const putBehavioralResourceLoadMoreClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_load_more_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralResourceOpen = ({ behavioral_id, resource_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_open`, { behavioral_id, resource_id });
};

export const putBehavioralResourceCreateCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_create_cta_clicked`, { behavioral_id });
};

export const putBehavioralSearchLensClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/search_lens_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralSearchLensCollectiveDirectoryCTAClicked = ({
  behavioral_id,
  action_details,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/search_lens_collective_directory_cta_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralSearchOptionsListItemClicked = ({
  behavioral_id,
  related_object_uuid,
  related_object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/search_options_list_item_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

export const putBehavioralGlobalDrawerCTAClicked = ({
  behavioral_id,
  related_object_uuid,
  related_object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/global_drawer_cta_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

export const putBehavioralPersonCardClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/person_card_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralPeopleListDrawerCTAClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/people_list_drawer_cta_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralPeopleListFiltersCTAClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/people_list_filters_cta_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralPeopleListCollectiveDirectoryCTAClicked = ({
  behavioral_id,
  related_object_type,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/people_list_collective_directory_cta_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

export const putBehavioralCommonGroundCarouselClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/common_ground_carousel_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralMeetSomeoneNewCarouselClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/meet_someone_new_carousel_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralMeetSomeoneNewViewProfileClicked = ({
  behavioral_id,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/meet_someone_new_view_profile_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralPeopleListCompanyDropdownClicked = ({
  behavioral_id,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/people_list_company_dropdown_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Company',
  });
};

export const putBehavioralAboutDrawerCTAClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/about_drawer_cta_clicked`, { behavioral_id });
};

export const putBehavioralTOCClicked = ({
  behavioral_id,
  action_details,
  related_object_type,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/toc_clicked`, {
    behavioral_id,
    action_details,
    object_type: related_object_type,
    object_uuid: related_object_uuid,
  });
};

export const putBehavioralPeopleDrawerFilterClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/people_drawer_filter_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralPeopleDrawerItemClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/people_drawer_item_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralMapLensClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/map_lens_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralMapNodeClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/map_node_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putBehavioralMapDetailsPageCTAClicked = ({
  behavioral_id,
  related_object_type,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/map_details_page_cta_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

export const putBehavioralNavProfileProgressBarClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/nav_profile_progress_bar_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralViewPublicProfileClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/view_public_profile_cta_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralEditProfileClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/edit_profile_cta_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralReachCardClicked = ({
  behavioral_id,
  related_object_uuid,
  related_object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/reach_card_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

export const putBehavioralBuildProfileClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/build_profile_cta_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralNoProjectsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/no_projects_cta_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralRequestChangeClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/request_change_cta_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralArticleContributorClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/article_contributor_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralArticleProfileLinkOutClicked = ({
  behavioral_id,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/article_profile_link_out_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralResourceCardSubmitterClicked = ({
  behavioral_id,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_card_submitter_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralResourceCardCreatorClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_card_creator_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralResourceDetailCreatorClicked = ({
  behavioral_id,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_details_creator_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralConveningAttendeeClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/convening_attendee_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralConveningHostClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/convening_host_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralShareButtonClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/share_button_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralReactionsListOpened = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/reactions_list_opened`, {
    behavioral_id,
  });
};

export const putBehavioralReactionsViewProfileClicked = ({
  behavioral_id,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/reactions_view_profile_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBehavioralReactionsProfileSeeAllClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/reactions_profile_see_all_clicked`, {
    behavioral_id,
  });
};

export const putBehavioralReactionsPersonDrawerCardClicked = ({
  behavioral_id,
  related_object_uuid,
  related_object_type,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/reactions_person_drawer_card_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

//COLLECTIVE DIRECTORY ONBOARDING
export const putCollectiveDirectoryOnboardingPromptClicked = ({
  behavioral_id,
  action_details,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_onboarding_prompt_clicked`, {
    behavioral_id,
    action_details,
  });
};

//COLLECTIVE DIRECTORY
export const putCollectiveDirectoryPersonCardClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_person_card_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putCollectiveDirectorySelectRecipientsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_select_recipients_clicked`, {
    behavioral_id,
  });
};

export const putCollectiveDirectoryRefineResultsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_refine_results_clicked`, {
    behavioral_id,
  });
};

export const putCollectiveDirectoryQuickFilterClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_quick_filter_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putCollectiveDirectoryProfileCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_continue_profile_clicked`, {
    behavioral_id,
  });
};

export const putCollectiveDirectorySearchClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_search_result_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putCollectiveDirectoryFilterMenuClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_filter_menu_clicked`, {
    behavioral_id,
  });
};

export const putCollectiveDirectoryFilterSectionClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_filter_section_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putCollectiveDirectoryToggleClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_toggle_filter_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putCollectiveDirectoryLoadMoreClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_load_more_clicked`, {
    behavioral_id,
  });
};

export const putCollectiveDirectoryPersonDrawerFilterClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_person_drawer_filter_clicked`, {
    behavioral_id,
  });
};

//CONTACT FLOW
export const putContactFlowSelectAllClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_select_all_clicked`, {
    behavioral_id,
  });
};

export const putContactFlowCopyProfileClicked = ({
  behavioral_id,
  related_object_uuid,
  action_details,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_copy_profile_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    person: 'Person',
    action_details,
  });
};

export const putContactFlowDeselectProfileClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_deselect_profile_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putContactFlowPartialMatchSelectionClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_partial_match_selection_clicked`, {
    behavioral_id,
  });
};

export const putContactFlowSendClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_send_clicked`, { behavioral_id });
};

export const putContactFlowBackClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_back_clicked`, { behavioral_id });
};

export const putContactFlowDismissed = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_dismissed`, { behavioral_id });
};

export const putContactFlowEditProfileClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/contact_flow_continue_profile_clicked`, {
    behavioral_id,
  });
};

export const putKyu101ProfileEditDrawerOpened = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_profile_edit_drawer_opened`, {
    behavioral_id,
    action_details,
  });
};

export const putKyu101PublicProfileCtaClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_public_profile_cta_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putKyu101ReachCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_view_connections_cta_clicked`, {
    behavioral_id,
  });
};

export const putKyu101PeopleSectionCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_people_section_cta_clicked`, {
    behavioral_id,
  });
};

export const putKyu101CompanyPageCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_company_page_cta_clicked`, {
    behavioral_id,
  });
};

export const putKyu101CompanyClientsClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_company_clients_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Company',
  });
};

export const putKyu101CompaniesDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_companies_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101ResourcesOpened = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_resources_opened`, {
    behavioral_id,
    action_details,
  });
};

export const putKyu101TheWellOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_the_well_opened`, {
    behavioral_id,
  });
};

export const putKyu101ConveningsOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_convenings_opened`, {
    behavioral_id,
  });
};

export const putKyu101UsecasesOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_usecases_opened`, {
    behavioral_id,
  });
};

export const putKyu101ContactFormOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_contact_form_opened`, {
    behavioral_id,
  });
};

export const putKyu101CasualIntentionCollectiveDirectoryOpened = ({
  behavioral_id,
  action_details,
}) => {
  return http.put(
    `/behaviorals/${behavioral_id}/kyu101_casual_intention_collective_directory_opened`,
    {
      behavioral_id,
      action_details,
    }
  );
};
export const putKyu101ExpertiseIntentionCollectiveDirectoryOpened = ({
  behavioral_id,
  action_details,
}) => {
  return http.put(
    `/behaviorals/${behavioral_id}/kyu101_expertise_intention_collective_directory_opened`,
    {
      behavioral_id,
      action_details,
    }
  );
};
export const putKyu101ConveneIntentionCollectiveDirectoryOpened = ({
  behavioral_id,
  action_details,
}) => {
  return http.put(
    `/behaviorals/${behavioral_id}/kyu101_convene_intention_collective_directory_opened`,
    {
      behavioral_id,
      action_details,
    }
  );
};
export const putKyu101ClientsDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_clients_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101GlobalIndustriesDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_global_industries_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101CollectiveDirectoryOpened = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_collective_directory_opened`, {
    behavioral_id,
    action_details,
  });
};

export const putKyu101GlobalDisciplineDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_global_discipline_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101GlobalIndustryDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_global_industry_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101GlobalClientDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_global_client_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101GlobalSkillDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_global_skill_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101GlobalPassionDrawerOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_global_passion_drawer_opened`, {
    behavioral_id,
  });
};

export const putKyu101ResourceLibraryOpened = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_resource_library_opened`, {
    behavioral_id,
  });
};

export const putKyu101FaqClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_faq_clicked`, {
    behavioral_id,
  });
};

export const putKyu101ModalMinimized = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_modal_minimized`, {
    behavioral_id,
  });
};

export const putKyu101ModalExpanded = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_modal_expanded`, {
    behavioral_id,
  });
};

export const putKyu101ModalDismissed = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_modal_dismissed`, {
    behavioral_id,
  });
};

export const putKyu101TocClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/kyu101_toc_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putTeamingPersonCardClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_person_card_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putTeamingRefineResultsClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_refine_results_clicked`, {
    behavioral_id,
  });
};

export const putTeamingSearchClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_search_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putTeamingFilterMenuClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_filter_menu_clicked`, {
    behavioral_id,
  });
};

export const putTeamingFilterSectionClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_filter_section_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putTeamingToggleClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_toggle_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putTeamingLoadMoreClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_load_more_clicked`, {
    behavioral_id,
  });
};

export const putTeamingContactBannerCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_banner_cta_clicked`, {
    behavioral_id,
  });
};

export const putTeamingDrawerSelectCtaClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_drawer_select_cta_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putTeamingDrawerViewProfileClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_drawer_view_profile_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putTeamingDrawerWorkExperienceToggled = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_drawer_work_experience_toggled`, {
    behavioral_id,
    action_details,
  });
};

export const putTeamingContactFlowMessageCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_message_cta_clicked`, {
    behavioral_id,
  });
};

export const putTeamingContactFlowShareCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_share_cta_clicked`, {
    behavioral_id,
  });
};

export const putTeamingContactFlowRecipientTabClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_recipient_tab_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putTeamingContactFlowEditMessageClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_edit_message_clicked`, {
    behavioral_id,
  });
};

export const putTeamingContactFlowRemoveRecipientClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_remove_recipient_clicked`, {
    behavioral_id,
  });
};

export const putTeamingContactFlowCcAdded = ({ behavioral_id, object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_cc_added`, {
    behavioral_id,
    object_uuid,
    object_type: 'Person',
  });
};

export const putTeamingContactFlowProfileDeselected = ({ behavioral_id, object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_profile_deselected`, {
    behavioral_id,
    object_uuid,
    object_type: 'Person',
  });
};

export const putTeamingContactFlowSendClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_send_clicked`, {
    behavioral_id,
  });
};

export const putTeamingContactFlowDismissed = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_dismissed`, {
    behavioral_id,
  });
};

export const putTeamingContactFlowBroadcastCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_broadcast_cta_clicked`, {
    behavioral_id,
  });
};

export const putTeamingContactFlowEditProfileClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_contact_flow_edit_profile_clicked`, {
    behavioral_id,
  });
};

export const putPulseTabClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_tab_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putPulseCompanySwitcherClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_company_switcher_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Company',
  });
};

export const putPulseDomainLeadClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_domain_lead_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putPulseContactCtaClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_contact_cta_clicked`, { behavioral_id });
};

export const putPulseWikiSearchClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_wiki_search_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putPulseWikiCardClicked = ({
  behavioral_id,
  related_object_type,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_wiki_card_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: related_object_type,
  });
};

export const putPulseSpotlightLinkClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_spotlight_link_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putPulseResourceClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_resource_clicked`, { behavioral_id });
};

export const putPulseFaqSectionTabClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/pulse_faq_section_tab_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putResourceVoteShareClicked = ({
  behavioral_id,
  action_details,
  related_object_uuid,
}) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_vote_share_clicked`, {
    behavioral_id,
    action_details,
    object_uuid: related_object_uuid,
    object_type: 'Resource',
  });
};

export const putBO24MostVotedClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/bo24_most_voted_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Resource',
  });
};

export const putBO24TopContributorClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/bo24_top_contributor_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putBO24CategoryClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/bo24_category_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putBO24TopEntryClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/bo24_top_entry_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Resource',
  });
};

export const putResourceBo24Filter = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/resource_bo24_filter_clicked`, {
    behavioral_id,
  });
};

export const putPersonDrawerBo24Clicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/person_drawer_bo24_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Person',
  });
};

export const putProfileResourceClicked = ({ behavioral_id, related_object_uuid }) => {
  return http.put(`/behaviorals/${behavioral_id}/profile_resource_clicked`, {
    behavioral_id,
    object_uuid: related_object_uuid,
    object_type: 'Resource',
  });
};

export const putCollectiveIndexNlpSearchClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_nlp_search_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putCollectiveIndexNlpSearchEdit = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_nlp_search_edit`, {
    behavioral_id,
  });
};

export const putCollectiveIndexFilterIconClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_filter_icon_clicked`, {
    behavioral_id,
  });
};

export const putCollectiveIndexFiltersApplied = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/collective_index_filters_applied`, {
    behavioral_id,
    action_details,
  });
};

export const putTeamingNlpSearchClicked = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_nlp_search_clicked`, {
    behavioral_id,
    action_details,
  });
};

export const putTeamingNlpSearchEdit = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_nlp_search_edit`, {
    behavioral_id,
  });
};

export const putTeamingFilterIconClicked = ({ behavioral_id }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_filter_icon_clicked`, {
    behavioral_id,
  });
};

export const putTeamingFiltersApplied = ({ behavioral_id, action_details }) => {
  return http.put(`/behaviorals/${behavioral_id}/teaming_filters_applied`, {
    behavioral_id,
  });
};
